import React, { useState } from "react";
import { graphql } from "gatsby";
import { className } from "../utils/helpers";
import { checkboxIcon, formMessagesIcon } from "../utils/icons";
import Seo from "../components/shared/Seo";
import Hero from "../components/shared/Hero";
import * as styles from "./styles/ContactPage.module.scss";

const ContactPage = ({ data }) => {
	const { data: pageData } = data.allPrismicContact.edges[0].node;
	const [formData, setFormData] = useState(null);
	const [message, setMessages] = useState({ success: false, error: false });
	const [acceptedTerms, setTermsAcceptance] = useState(null);

	function encode(data) {
		return Object.keys(data)
			.map(
				(key) =>
					`${encodeURIComponent(key)}=${encodeURIComponent(
						data[key]
					)}`
			)
			.join("&");
	}

	const handleInputChange = ({ target }) => {
		setFormData({ ...formData, [target.name]: target.value });
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		if (!formData) return;

		fetch("/", {
			method: "POST",
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: encode({
				"form-name": e.target.getAttribute("name"),
				...formData,
			}),
		})
			.then(() => {
				setMessages({ ...message, success: true });
				setTimeout(() => setMessages({ ...message, success: false }), 1500);
			})
			.catch((_error) => setMessages({ ...message, error: true }))
			.finally(() => setFormData(null));
	};

	return (
		<>
			<Seo
				title={pageData.title.text}
				description={pageData.description.text}
			/>

			<div className={styles.contactPage}>
				<Hero
					picture={pageData.hero_picture}
					title={pageData.hero_title.text}
					altShape={true}
					fullHeight={true}
				/>

				<div className={styles.contactData}>
					<div>
						{pageData.contact_data.map((data, i) => (
							<div key={i} className={styles.dataBlock}>
								<img src={data.icon.url} alt={data.icon.alt} />
								<p>{data.text.text}</p>
							</div>
						))}
					</div>
				</div>

				<div className={styles.formBlock}>
					<div className={styles.references}>
						<ul>
							{pageData.map_references.map((x, i) => (
								<li key={i}>
									<img src={x.icon.url} alt={x.icon.alt} />
									<span>{x.text.text}</span>
								</li>
							))}
						</ul>
					</div>

					{!message.success && !message.error && (
						<form
							name="contacto"
							method="POST"
							data-netlify="true"
							netlify-honeypot="bot-field"
							onSubmit={handleSubmit}
						>
							<h3>{pageData.form_title.text}</h3>

							<div>
								<label htmlFor="nombre">
									{pageData.form_name_field.text}
								</label>

								<input
									type="text"
									name="nombre"
									required
									onChange={handleInputChange}
								/>
							</div>

							<div>
								<label htmlFor="telefono">
									{pageData.form_phone_field.text}
								</label>

								<input
									type="tel"
									name="telefono"
									required
									onChange={handleInputChange}
								/>
							</div>

							<div>
								<label htmlFor="mail">
									{pageData.form_mail_field.text}
								</label>

								<input
									type="email"
									name="mail"
									required
									onChange={handleInputChange}
								/>
							</div>

							<div>
								<label htmlFor="mensaje">
									{pageData.form_message_field.text}
								</label>

								<textarea
									name="mensaje"
									required
									onChange={handleInputChange}
								/>
							</div>

							<div className={styles.termsAcceptanceCheckbox}>
								<input
									type="checkbox"
									onChange={() =>
										setTermsAcceptance(!acceptedTerms)
									}
								/>
								{
									checkboxIcon[
										acceptedTerms ? "active" : "inactive"
									]
								}
								<label>{pageData.form_legal_field.text}</label>
							</div>

							<button
								className="base-cta"
								type="submit"
								disabled={!acceptedTerms}
							>
								Enviar
							</button>
						</form>
					)}

					{(message.success || message.error) && (
						<div
							{...className(styles.submitMessage, {
								[styles.success]: message.success,
								[styles.error]: message.error,
							})}
						>
							{message.success && formMessagesIcon.success}
							{message.error && (
								<>
									{formMessagesIcon.error}

									<button
										className="base-cta"
										onClick={() =>
											setMessages({
												success: false,
												error: false,
											})
										}
									>
										Intentar de nuevo
									</button>
								</>
							)}
						</div>
					)}
				</div>

				<div
					className={styles.map}
					dangerouslySetInnerHTML={{ __html: pageData.map_code.text }}
				/>
			</div>
		</>
	);
};

export const query = graphql`
	{
		allPrismicContact {
			edges {
				node {
					data {
						title {
							text
						}
						description {
							text
						}
						hero_title {
							text
						}
						hero_picture {
							fluid(maxWidth: 800) {
								src
							}
							alt
						}
						contact_data {
							icon {
								url
								alt
							}
							text {
								text
							}
						}
						form_title {
							text
						}
						form_name_field {
							text
						}
						form_phone_field {
							text
						}
						form_mail_field {
							text
						}
						form_message_field {
							text
						}
						form_legal_field {
							text
						}
						map_code {
							text
						}
						map_references {
							icon {
								url
								alt
							}
							text {
								text
							}
						}
					}
				}
			}
		}
	}
`;

export default ContactPage;
