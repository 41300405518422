// extracted by mini-css-extract-plugin
export var contactData = "ContactPage-module--contactData--9P-0W";
export var contactPage = "ContactPage-module--contactPage--frsVE";
export var dataBlock = "ContactPage-module--dataBlock--Eodm3";
export var error = "ContactPage-module--error--pLCRD";
export var fadeIn = "ContactPage-module--fade-in--V4cHS";
export var formBlock = "ContactPage-module--formBlock--TOmqm";
export var map = "ContactPage-module--map--9bLWN";
export var references = "ContactPage-module--references--P5e+r";
export var submitMessage = "ContactPage-module--submitMessage--BpOzT";
export var success = "ContactPage-module--success--FDSmq";
export var termsAcceptanceCheckbox = "ContactPage-module--termsAcceptanceCheckbox--3kO1i";